<template>
  <section
    class="column mb-4 w3-border w3-mobile cursor w3-hover-sand w3-hover-text-red hover-effect mb-4"
  >
    <div class="card-body center-vertical m-0 p-4 w3-center bg-transparent">
      <div class="bold w3-block">
        <p class="m-0 w3-block">
          <i class="bx bx-plus size50"></i>
        </p>
        <p class="mt-0 w3-block">{{ label }}</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["label"],
};
</script>